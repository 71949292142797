import React from 'react';
import {
	I18nextProvider,
	withTranslation
} from 'react-i18next';
import i18n from './i18next';

export function withTrans(Component: any) {

	const WrappedComponent = withTranslation()(Component);

	return (props?: any) => {
		return (
			<I18nextProvider i18n={i18n}>
				<WrappedComponent {...props} language={i18n.language} />
			</I18nextProvider>
		);
	};
}

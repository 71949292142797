import { Box } from "@chakra-ui/react";
import { StaticImage } from 'gatsby-plugin-image';
import React, { useContext, useState } from 'react';
import { SlugLinksContext } from '../../../context';
import { withTrans } from '../../i18n/withTrans';
import { LanguageSelector } from '../LanguageSelector';
import { Container } from '../Layout/GlobalStyles';
import { CloseIcon, MenuIcon } from './Icons';
import {
	Logo,
	NavItem, NavLink, NavMenu,
	NavSection,
	NavStyles
} from './Nav.styles';

const Nav = ({ t, children }: any) => {
	const [isOpen, setIsOpen] = useState(false);
	const toggle = () => setIsOpen(!isOpen);

	return (
		<NavBarContainer>
			<MenuToggle toggle={toggle} isOpen={isOpen} />
			<MenuLinks setIsOpen={setIsOpen} isOpen={isOpen} t={t}>
				{children}
			</MenuLinks>
		</NavBarContainer>
	);
};


const NavBarContainer: React.FC = ({children}) => {
  return (
		<NavStyles>
			<Container>{children}</Container>
		</NavStyles>
	);
};


const MenuLinks = ({
	isOpen,
	setIsOpen,
	t
}: {
	isOpen: boolean;
	setIsOpen: React.SetStateAction<boolean>;
	t: any;
}) => {

	const slugLinks: any = useContext(SlugLinksContext);
	return (
		<Box
			display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
			height={{ base: isOpen ? '100vh' : '100%' }}
		>
			<NavSection>
				<Logo to={`/${slugLinks.lang}`} onClick={() => setIsOpen(false)}>
					<StaticImage
						src="../../images/Logo.png"
						alt="Regina Logotype"
						placeholder="blurred"
					/>
				</Logo>
				<NavMenu onClick={() => setIsOpen(false)}>
					<NavItem>
						<NavLink to={`/${slugLinks.lang}`}>{t('navigation.main')}</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/${slugLinks.lang}#services`} stripHash>
							{t('navigation.service')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/${slugLinks.lang}#brands`} stripHash>
							{t('navigation.brands')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/${slugLinks.lang}#about`} stripHash>
							{t('navigation.about')}
						</NavLink>
					</NavItem>
					<NavItem>
						<NavLink to={`/${slugLinks.lang}#contact`} stripHash>
							{t('navigation.contact')}
						</NavLink>
					</NavItem>
				</NavMenu>
				<LanguageSelector />
			</NavSection>
		</Box>
	);
};

const MenuToggle = ({ toggle, isOpen }: any) => {
  return (
		<Box
			display={{ base: 'block', md: 'none' }}
			w={{ base: '25px' }}
			onClick={toggle}
		>
			{isOpen ? <CloseIcon /> : <MenuIcon />}
		</Box>
	);
};

export default withTrans(Nav);
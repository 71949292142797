import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import Backend from 'i18next-http-backend';
import { initReactI18next } from "react-i18next";
import config from './config';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    fallbackLng: 'lv',
    debug: false,
    load: 'languageOnly',
    ns: 'translation',
    defaultNS: 'translations',
    lowerCaseLng: true,
    supportedLngs: ['en', 'ru', 'lv'],
    detection: {
      order: ['path', 'navigator']
    },
    resources: {
      ...config
    },
    interpolation: {
      escapeValue: false,
    },
    react: {
      useSuspense: false,
    },
});

export default i18n;
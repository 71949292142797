import 'normalize.css';
import React from 'react';
import Theme from '../../theme/Theme';
import { GlobalStyles } from './GlobalStyles';

export default function Layout({ children }: any) {
	return (
		<Theme>
			<GlobalStyles />
			{children}
		</Theme>
	);
}

import styled, { createGlobalStyle } from 'styled-components';

export const GlobalStyles = createGlobalStyle`

  *, *::before, *::after {
      box-sizing: border-box;
      padding: 0;
      margin: 0;
  }

  html {
    height: 100%;
    scroll-behavior: smooth;
    font-size: 12px;
  }

  body {
      font-family: 'Roboto', sans-serif;
      font-display: swap;
      position: relative;
      font-size: 1.4rem;
      display:flex;
      flex-direction:column;
  }

  .gatsby-image-wrapper img[src*=base64\\\,] {
      image-rendering: -moz-crisp-edges;
      image-rendering: pixelated;
  }

  img {
      max-width: 100%;
      height: auto;
  }

  .line-break {
    white-space: pre-wrap;
    text-align: justify;
  }

  ul {
    list-style-type: none;
  }

  h1, h2, h3, h4, h5, h6 {
    color: #46474a;
  }

  @media ${(props) => props.theme.breakpoint.mobile} {
    html {
      font-size: 10px;
    }

    h1 {
      font-size: 2.3rem;
    }
  }

`;

export const Container = styled.div`
  max-width: 1100px;
  padding-left: 35px;
  padding-right: 35px;
  margin-left: auto;
  margin-right: auto;
`;


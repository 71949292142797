import { Link } from 'gatsby';
import { AnchorLink } from 'gatsby-plugin-anchor-links';
import styled from 'styled-components';

export const NavStyles = styled.nav`
	background-color: ${(props) => props.theme.colors.secondary};
	color: #fff;
	position: fixed;
	top: 0;
	backface-visibility: hidden;
	z-index: 20;
	width: 100%;
	padding: 12px;
	font-family: 'Roboto', sans-serif;

	@media ${(props) => props.theme.breakpoint.mobile} {
		padding: 25px 0;
	}
`;

export const NavSection = styled.section`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 4rem;

	@media ${(props) => props.theme.breakpoint.mobile && props.theme.breakpoint.tablet} {
		flex-direction: column;
	}
`;

export const Logo = styled(Link)`
	height: 105px;
	max-width: 80px;
`;


export const NavMenu = styled.ul`
	display: flex;
	align-items: center;
	text-align: center;

	@media ${(props) => props.theme.breakpoint.mobile && props.theme.breakpoint.tablet} {
		display: block;
	}
`

export const NavItem = styled.li`
	text-transform: uppercase;
	letter-spacing: 1.2px;
	font-weight: 300;
	position: relative;
	margin: 0 16px;

	&:after {
		position: absolute;
		content: '';
		left: 0;
		bottom: 8px;
		height: 2px;
		width: 100%;
		background: ${(props) => props.theme.colors.linkHover};
		opacity: 0;
		transition: all 0.4s linear;
	}

	&:hover:after {
		opacity: 1;
		bottom: 0;
	}

	@media ${(props) => props.theme.breakpoint.mobile && props.theme.breakpoint.tablet} {
		line-height: 3.6rem;
	}
`;

export const NavLink = styled(AnchorLink)`
	&:hover {
		color: ${(props) => props.theme.colors.linkHover};
	}
`;

module.exports = {
  en: {
    path: `en`,
    locale: `en-US`,
    siteLanguage: 'English',
    translations: require('../i18n/translations/en.json')
  },
  ru: {
    path: `ru`,
    locale: `ru-RU`,
    siteLanguage: 'Русский',
    translations: require('../i18n/translations/ru.json')
  },
  lv: {
    path: `lv`,
    locale: `lv-LV`,
    siteLanguage: 'Latviešu',
    translations: require('../i18n/translations/lv.json')
  },
}
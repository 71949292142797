import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { withTrans } from '../../i18n/withTrans';
;

const FooterStyle = styled.footer`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	background-color: ${(props) => props.theme.colors.secondary};
	color: #fff;
	padding: 25px 0 25px;
	position: relative;

	p {
		text-align: center;
	}
`;

export const PhoneNumber = () => {
	return (
		<div style={{ textAlign: 'center' }}>
			<Link to="/">+371 29486016</Link>
			<p style={{ color: '#cacaca' }}>(Telegram / WhatsApp)</p>
		</div>
	);
};

const Footer = ({t}) => {
	return (
		<FooterStyle>
			<PhoneNumber />
			<p>
				&copy; {new Date().getFullYear()} «SIA REGINA TRADE».
				{t('footer.copyright')}
			</p>
		</FooterStyle>
	);
};

export default withTrans(Footer);
import styled from 'styled-components';
import enFlag from '../../images/en.svg';
import lvFlag from '../../images/lv.svg';
import ruFlag from '../../images/ru.svg';

export const Select = styled.div`
	position: relative;
	font-weight: 300;
	z-index: 10;

	li {
		border-radius: 5px;
		padding: 8px;
	}

	ul.dropdown {
		display: none;
	}

	ul.dropdown li {
		display: block;
		&:hover {
			background-color: #d6d6d6;
		}
		a {
			color: #222;
		}
	}

	ul li:hover ul.dropdown {
		display: block;
	}

	ul.dropdown {
		background-color: rgba(255, 255, 255, 0.9);
		box-shadow: 0 1px 10px rgba(0, 0, 0, 0.2);
		border-radius: 5px;
		margin: 0;
		width: 140px;
		padding: 0;
		position: absolute;
		top: 30px;
		right: 0;
	}

	a {
		letter-spacing: 1.2px;
		color: #fff;
		padding: 6px 10px;
	}

	.flag-icon {
		position: relative;
		right: 5px;
	}

	.flag-icon-ru {
		background-image: url(${ruFlag});
	}

	.flag-icon-lv {
		background-image: url(${lvFlag});
	}

	.flag-icon-us {
		background-image: url(${enFlag});
	}

`;
import React from 'react';
import { SlugLinksContext } from './context.ts';
import Footer from './src/components/Footer';
import Layout from './src/components/Layout/Layout';
import { Nav } from './src/components/Navigation';

export const wrapPageElement = ({ element, props }) => {

	return (
		<Layout {...props}>
			<SlugLinksContext.Provider value={props.pageContext}>
				<Nav />
			</SlugLinksContext.Provider>
			{element}
			<Footer />
		</Layout>
	);
};

import 'flag-icon-css/css/flag-icon.css';
import { Link } from 'gatsby';
import React, { useContext, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SlugLinksContext } from '../../../context';
import { Select } from './Select';

const LanguageSelector = () => {

	const slugLinks: any = useContext(SlugLinksContext);
	const [slugs, setSlugs] = useState([]);
	const { i18n } = useTranslation();
	const [language, setLanguage] = useState(slugLinks.lang);
	const [languageIsOpen, setLanguageIsOpen] = useState(false);

	const getLocale = (lang: string) => {
		return i18n.options.resources![lang]?.locale.split('-')[1].toLowerCase();
	};

	const selectLanguage = (event: React.ChangeEvent<any>) => {
		setLanguageIsOpen(!languageIsOpen);
		const lang = event.target.lang;
		if (lang) {
			i18n.changeLanguage(lang);
			setLanguage(lang);
		}
	};

	useEffect(() => {
		const cat = slugLinks?.category;
		if(cat) {
			const slugs = slugLinks.slugs.filter((item) => item.category === cat);
			setSlugs(slugs);
			return;
		}
		setSlugs(slugLinks.slugs);
	}, [slugLinks])

	return (
		<Select>
			<ul onClick={selectLanguage}>
				<li>
					<Link to={`/${slugLinks.lang}`} lang={language}>
						<span
							className={`flag-icon flag-icon-${getLocale(language)}`}
						></span>
						{i18n.options.resources![language]?.siteLanguage}
					</Link>

					<ul className="dropdown">
						{slugs
							?.filter((el: any) => el.lang !== slugLinks.lang)
							.map((el: any) => [
								<li key={el.lang}>
									<Link to={el.slug} lang={el.lang}>
										<span
											className={`flag-icon flag-icon-${getLocale(el.lang)}`}
										></span>
										{i18n.options.resources![el.lang]?.siteLanguage}
									</Link>
								</li>
							])}
					</ul>
				</li>
			</ul>
		</Select>
	);
}

export default LanguageSelector;
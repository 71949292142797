// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-brands-spradling-tsx": () => import("./../../../src/templates/brands-spradling.tsx" /* webpackChunkName: "component---src-templates-brands-spradling-tsx" */),
  "component---src-templates-brands-sunbrella-tsx": () => import("./../../../src/templates/brands-sunbrella.tsx" /* webpackChunkName: "component---src-templates-brands-sunbrella-tsx" */),
  "component---src-templates-services-tsx": () => import("./../../../src/templates/services.tsx" /* webpackChunkName: "component---src-templates-services-tsx" */)
}


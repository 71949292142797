import { ChakraProvider, extendTheme } from '@chakra-ui/react';
import React from 'react';
import { DefaultTheme, ThemeProvider } from 'styled-components';
declare module 'styled-components' {
	export interface DefaultTheme extends ReginaTheme{}

	type ReginaTheme = {
		colors: Color;
		font: Font;
		breakpoint: BreakPoint;
	};

	type Color = {
		primary: string;
		secondary: string;
		heading: string;
		linkHover: string;
	};

	type Size = {
		extraLarge: string;
		large: string;
		medium: string;
		small: string;
		hyperlink: string;
		paragraph: string;
		extraSmall: string;
	};

	type Font = {
		sizes: Size;
		family: string;
	};

	type BreakPoint = {
		mobile: string;
		tablet: string;
		laptop: string;
		desktop: string;
	};
}

const theme: DefaultTheme = extendTheme({
	colors: {
		primary: '#5039E7',
		secondary: '#372E4B',
		heading: '#4D5062',
		linkHover: '#33ffff'
	},
	font: {
		sizes: {
			extraLarge: '7.2rem',
			large: '5.2rem',
			medium: '3.6rem',
			small: '2rem',
			hyperlink: '1.8rem',
			paragraph: '1.6rem',
			extraSmall: '1.4rem'
		},
		family: 'sans-serif'
	},
	breakpoint: {
		mobile: 'only screen and (max-width: 475px)',
		tablet: 'only screen and (max-width: 800px)',
		laptop: 'only screen and (max-width: 1200px)',
		desktop: 'only screen and (max-width: 1600px)'
	}
});

const Theme = ({children}: any) => {
	return (
		<ThemeProvider theme={theme}>
			<ChakraProvider resetCSS theme={theme}>{children}</ChakraProvider>
		</ThemeProvider>
	);
}
export default Theme;